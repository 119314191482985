
:root{
  --clr-primary: #f35744;
  --clr-secundary: #008058;
  --clr-gray-light: #b0bec5;
  --clr-gray-dark: #858585;
}

body {
  /*margin: 0;*/
  /*font-family: Dubai, serif !important;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: white;*/
  /*height: 100vh;*/
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  /*color: var(--clr-gray-light);*/
}

  /*background: #EB3349;  !* fallback for old browsers *!*/
  /*background: -webkit-linear-gradient(to right, #F45C43, #EB3349);  !* Chrome 10-25, Safari 5.1-6 *!*/
  /*background: linear-gradient(to right, #F45C43, #EB3349); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
  /*padding-top: 50px;*/


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;

}

html, body {
  height: 100vh; width: 100vw;
  margin: 0;
  padding: 0;
}

.spinner{

  position: fixed;
  top: 50%;
  left: 50%;

}

*:focus {
  outline: 0 !important;
}

