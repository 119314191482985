.tracker-popup-rapper{
    font-size: 13px;

}

.button-div button{
    /*font-size: 13px;*/
    color: #f0f0e6;
    background-color: var(--clr-secundary);
    border-radius: 4px;
    /*border: var(--clr-secundary) 1px solid;*/
    border: none;

    /*margin: auto;*/
    /*border: 1px solid green;*/
    /*border: solid blueviolet;*/
    padding: 4px  4px;
    transition-duration: 0.4s;
    /*float: right;*/

}
.button-div button:hover{
    background-color: var(--clr-primary);
    border: 1px solid var(--clr-primary);
}

.button-div{

    display: flex;
    justify-content: flex-end;
}

.tracker-list-title{
    padding-top: 0.2em;
    /*padding-bottom: 0.1em;*/

}

.tracker-ulist{

    display: flex;
    flex-direction: column;
    list-style-type: none;
    /*border: #0288d1 solid;*/
    font-weight: bold;

}

.tracker-ilist{
    display: flex;
    justify-content: space-between;
}


.tracker-data-item{
    /*border: blueviolet solid;*/
    width: 40%;
}
