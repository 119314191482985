.competitorIcon{
    background-color: transparent;
    opacity: .75 !important;

}

.mapAllContainer{
    height: 100vh;

}

.divIcon{
    background-color: transparent;

}