
.voucher-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.voucher-box{

    position: relative;
    top: -40px;
    width: 450px;
    height: 150px;
    border: solid 2px;
    margin-left: auto;
    border-radius: 15px;
    margin-right: auto;
    background-color: #eae3e3;
    animation-name: voucher-modal-animation;
    animation-duration:1s;

}

@keyframes voucher-modal-animation {

    0%{top: -500px}
    100%{top: 0}
}

.voucher-footer{
    margin-left: 16px;
}
.voucher-footer h4{
    font-size: 18px;
    padding: 10px 5px;
}
.voucher-footer img{
    /*position: relative;*/
    /*display:inline;*/
    width: 75px;
    height: 40px;
    /*border: #0A253C solid 1px;*/
    /*top: -100px;*/
    /*left: -30px;*/

}

.voucher-content{
    display: none;
    /*overflow: auto;*/
    /*width: 100%;*/
    /*height: 75%;*/
    /*background-color: white;*/
}

.voucher-box button{
    position: absolute;
    bottom: 10px;
    right: 20px;
    background-color: var(--clr-secundary);
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    border: none;
}