

.banner-rapper{
    position: relative;
    background-image: url(../../../images/upasport-luis.jpg);
    width: 100%;
    height: 82vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner-navbar{
    width: 100%;
    height: 8vh;
    font-size: 24px;
    /*font-weight: 600;*/
    color: white;
    display: flex;
    justify-content: left;
    align-items: center;

}
.banner-navbar a{
    color: white;
    margin-left: 30px;
}

.banner-rapper p{
    font-size: 48px;
    /*font-weight: 600;*/
    color: white;
    width: 451px;
    height: auto;
    position: absolute;
    left: 65vw;
    top: 37vh;
    line-height: 65px;

}

.logo-wrapper{
    width: 10em;
    height: 7.1em;
    /*align-items: center;*/
    /*border: solid 2px blueviolet;*/
    position: absolute;
    top: 67vh;
}
.logo-wrapper img{
    width:9.8em;
    height: 8em;
}

.widgets-rapper{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    width: 100%;
    height: 18vh;
    /*border: solid 2px chartreuse;*/
}
.widgets-rapper div{
    padding-top: 1.3vh;
    /*box-sizing: border-box;*/
    text-align: center;
    background-color: #F5EDED;
    color: var(--clr-primary);
    /*font-size: 18px;*/
    font-size: 1.12em;
    font-weight: 600;
    /*border: solid 2px chartreuse*/
}

@media screen and (max-width: 1110px){
    .hero-rapper{
        font-size: 13px;
    }
    .banner-rapper p{
        font-size: 32px;
        width: 300px;
        line-height: 45px;
        top:50vh

    }
    .banner-navbar{
        font-size: 18px;

    }

}

@media screen and (max-width: 700px){
    .banner-rapper p{
        font-size: 26px;
        width: 170px;
        line-height: 30px;
        top:55vh;

    }
    /*.banner-navbar{*/
    /*    font-size: 18px;*/

    /*}*/

}
@media screen and (max-width: 500px){
    .banner-rapper p{
        font-size: 24px;
        width: 170px;
        line-height: 30px;
        top:55vh;
        left: 50vw;

    }

}
