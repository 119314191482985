
.checkpoint-list{
    list-style-type: none;
    font-size: 14px;
    font-weight: bold;
    /*font-family: Dubai, monospace;*/
}
/*.checkpoint-list h5::before{*/
/*    !*content: url(//unsplash.it/30/30);*!*/
/*    content: 'prueba';*/
/*    border: solid black 2px;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*}*/

.checkpoint-list h5{
    display: inline-block;
    position: relative;
    left: 7px;

}

.checkpoint-list img{
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-top: -10px;
}

.data-item{
    display: inline-block;
    /*   border: solid 2px; */
    position:absolute;
    /*left:255px;*/
    left: 245px;
}

.list-item{
    display: inline-block;
    width:78%
}

.leaflet-popup-content-wrapper {
    border-radius: 0;
    width: 390px;
    background-color: #f0f0e6;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}