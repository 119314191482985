.checkpoints-table-wrapper{
    /*font-family: Dubai, serif;*/
    /*border: black solid 3px;*/
    padding-right: 20px;
    padding-left: 20px;
    /*width: 445px;*/
}


.checkpoints-table{
    border-collapse: collapse;
    font-size: 0.7em;
    margin: 10px 0;
    overflow: hidden;

}

.checkpoints-table th {
    padding: 3px 12px;

}
.checkpoints-table td{
    padding: 5px 12px;
    font-weight: 500;
    white-space: nowrap;

}
.checkpoints-table thead tr {

    background-color: #008058;
    text-align: left;
    font-weight: bold;
    color: white;

}

.checkpoints-table tbody tr{
    border-bottom: 1px solid #dddddd;
}

@media (max-width: 650px) {

    .checkpoints-table-wrapper{
        width: 100%;
    }

    .checkpoints-table-wrapper h4{
        text-align: center;
        margin-top: 7px;

    }

    .checkpoints-table th{
        display: none;
    }

    .checkpoints-table, .checkpoints-table tbody, .checkpoints-table tr, .checkpoints-table td{
        display: block;
        width: 100%;

    }

    .checkpoints-table tbody tr td{
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .checkpoints-table tbody tr{
        border-bottom: #008058 solid 3px;
    }

    .checkpoints-table td::before{
        content: attr(data-label);
        position: absolute;
        left: 20px;
        font-weight: 600;
    }

}