.checkpoints-modal-container{
    position: fixed;
    /*top: 20%;*/
    /*left: 50%;*/
    /*transform: translate(-50%,-50%);*/
    /*background-color: #f0f0e6;*/
    background-color: white;
    padding: 15px;
    z-index: 1000;
    /*border-radius: 10px;*/
    width: 500px;
    /*box-shadow: 2px 2px #888888;*/
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,.1);
    animation-name: voucher-modal-animation;
    animation-duration:0.5s;
}
@keyframes voucher-modal-animation {

    0%{top: -500px}
    100%{top: 0}
}


@media (max-width: 650px){
    .checkpoints-modal-container{
        padding: 0;
        position: fixed;
        width: 95vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

    }
}

.checkpoints-modal-button > button{
    float: right;
    font-size: 15px;
    color: #f0f0e6;
    background-color: #008058;
    border-radius: 4px;
    margin: auto;
    border: none;
    padding: 0px 4px 4px 4px;
    transition-duration: 0.4s;
}

.checkpoints-modal-button > button:hover{
    background-color:#f35744;
    border: 1px solid #f35744;
}

.checkpoints-modal-x-button {
    float: right;
    box-sizing: border-box;
    color: #c3c3c3;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    width: 18px;
    height: 14px;
    box-shadow: none;
    text-decoration: none;
    font:16px/14px Tahoma, Verdana, sans-serif;
    font-weight: bold;
    background: transparent;
}
button.checkpoints-modal-x-button{
    border: none;
    /*outline: none;*/
}
button.checkpoints-modal-x-button:hover{
    color: #999;
}
