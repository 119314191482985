

#tab1{
    font-size: 0.9rem;

}
#tab2{
    font-size: 0.9rem;}
#tab3{
    font-size: 0.9rem;
}

@media screen and (max-width: 550px){

    #tab1{
        font-size: 0.7rem;
    }
    #tab2{
        font-size: 0.7rem;
    }
    #tab3{
        font-size: 0.7rem;
    }

}

