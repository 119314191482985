
.leaflet-container {
    height: 100%;
    width: 100vw;
    margin-left: 0px;
    border: 1px outset #f35744;
}

.seeker-dashboard-container{
    height: 100vh;
    width: 100%;
}


.competitor-loc-icon{
    background-color: transparent;
    opacity: .9 !important;
}

