.leaflet-popup-content-wrapper {
    border-radius: 0;
    width: 340px;
    background-color: #f0f0e6;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    font-family: Dubai, sans-serif;
}


/*.ul-location-popup{*/
/*    list-style-type: none;*/
/*    font-size: 13px;*/
/*    font-weight: bold;*/
/*    font-family: Dubai, sans-serif;*/
/*}*/

.location-popup-title{

    font-size: 1.5em;
    font-weight: bold;
}

.location-popup-details{
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}
.location-popup-details:nth-child(even){
    alignment: right;
}

#div-link{
    border-bottom: 1px solid black;

}
.checkpoints-button{
    float: right;
    font-size: 15px;
    color: #f0f0e6;
    background-color: #008058;
    border-radius: 4px;
    margin: auto;
    border: 1px solid green;
    padding: 4px 4px 2px;
    transition-duration: 0.4s;
}
.checkpoints-button:hover{
    background-color:#f35744;
    border: 1px solid #f35744;
}
.div-checkpoints-button{
    margin-top: 4px;
    margin-bottom: 4px;
    height: 15px;
}


