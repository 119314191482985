.competitor-detail-table-wrapper{
    /*font-family: Dubai, serif;*/
    /*border: black solid 3px;*/
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
    /*width: 445px;*/
}


.competitor-detail-table{
    border-collapse: collapse;
    font-size: 0.9em;
    /*margin: 10px 0;*/
    overflow: hidden;

}

.competitor-detail-table th {
    padding: 3px 12px;

}
.competitor-detail-table td{
    padding: 5px 12px;
    font-weight: 500;
    white-space: nowrap;

}
.competitor-detail-table thead tr {

    background-color: #008058;
    text-align: left;
    font-weight: bold;
    color: white;

}

.competitor-detail-table tbody tr{
    border-bottom: 1px solid #dddddd;
}

@media (max-width: 650px) {

    .competitor-detail-table-wrapper{
        width: 100%;
    }

    /*.competitor-detail-table-wrapper h4{*/
    /*    text-align: center;*/
    /*    margin-top: 7px;*/

    /*}*/

    .competitor-detail-table th{
        display: none;
    }

    .competitor-detail-table, .competitor-detail-table tbody, .competitor-detail-table tr, .competitor-detail-table td{
        display: block;
        width: 100%;

    }

    .competitor-detail-table tbody tr td{
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .competitor-detail-table tbody tr{
        border-bottom: #008058 solid 3px;
    }

    .competitor-detail-table td::before{
        content: attr(data-label);
        position: absolute;
        left: 20px;
        font-weight: 600;
    }

}