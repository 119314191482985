.payment-form-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

}

.payment-form-container{
    position: relative;
    /*position: fixed;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%,-50%);*/
    background-color: white;
    border-radius: 10px;
    animation-name: oxxo-form-modal-animation;
    animation-duration: 2s;
    width: 350px;
    padding: 20px;
    font-size: 14px;

}

@keyframes oxxo-form-modal-animation {

    0%{top:-500px;}

    100%{top: 0;}
}

.payment-form-container img[alt="race picture"]{
    max-width: 200px;
    margin-bottom: 10px;
    border-radius: 3px;
}

.payment-form-container img[alt="oxxo icon"]{

    margin-bottom: 150px;
    margin-left: 40px;
}

.oxxo-payment-form-title{
    border-bottom: #F5EEEE solid 2px;
}


.payment-form-close-button{
    position: absolute;
    right: 10px;
    background-color: transparent;
    border: none;

}

.payment-form-close-button:hover{
    padding: 1px;
}


#payment-form{

    /*width: 480px;*/
    /*margin: auto;*/
}   

/*#payment-form form > * {*/
/*    margin: 10px 0;*/
/*}*/


#payment-form button {
    background: var(--clr-secundary);
    border-radius: 3px;
    color: white;
    border: 0;
    padding: 6px 8px;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
}
#payment-form button:hover {
    filter: contrast(115%);
}
#payment-form button:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
}
#payment-form button:disabled {
    opacity: 0.5;
    cursor: none;
}

#payment-form input, select {
    display: block;
    /*font-size: 1.1em;*/
    width: 100%;
    /*margin-bottom: 10px;*/
}

#payment-form label {
    display: block;
}

#payment-form small {
    font-size: .6em;
}

#payment-form fieldset, input, select {
    border: 1px solid #efefef;
}
#payment-form fieldset{
    box-shadow:0 2px 4px 0 rgba(0,0,0,.2)
}


#payment-form {
    border: #F6F9FC solid 1px;
    border-radius: 3px;
    padding: 20px;
    /*margin: 15px 0;*/
    /*box-shadow: 0 30px 50px -20px rgb(50 50 93/25%), 0 30px 60px -30px rgb(0 0 0 / 30%);*/
}

#messages {
    /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';*/
    display: none; /* hide initially, then show once the first message arrives */
    background-color: #0A253C;
    color: #00D924;
    padding: 20px;
    margin: 20px 0;
    border-radius: 3px;
    font-size:0.7em;
}