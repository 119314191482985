
.table-results-container {
    /*width: 80vw;*/
    /*border: #d4ff77 solid 2px;*/
    /*margin-left: 100px;*/
    /*font-size: 0.9vw ;*/
    font-size: clamp(0.6rem, 0.9vw, 1rem);

}

.table-results-container table{
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    /*border-radius: 10px;*/
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    border-collapse: collapse;
    /*border-radius: 10px;*/
    table-layout: fixed;
}

.table-results-container table th:first-of-type {
    border-radius: 10px 0 0 0;
}

.table-results-container table th:last-of-type{
    border-radius:  0 10px 0 0;
}

.table-results-container input{
    border-radius: 4px;
    border-color: transparent;
    margin-left: 5vw;
    margin-bottom: 1vw;
    margin-top: 1vw;
    padding: 5px;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    display: inline-block;

}
.table-results-container h4{
    display: inline-block;
    margin-left: 15vw;
    margin-right: 10vw;
    font-size: 2vw;
}

.table-results-container select {
    display: inline-block;
    margin-left: 5vw;
    margin-bottom: 1vw;
    margin-top: 1vw;
    padding: 5px;
    width: 190px;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
}

.table-results-container tbody tr:last-of-type{
    border-bottom: 2px solid var(--clr-secundary);
}

.table-results-container td, .table-results-container th {
    /*border: 1px solid #ddd;*/
    /*padding: 8px;*/
    padding-top: 0.35vw;
    padding-bottom: 0.35vw;
    padding-left: 0.25vw;
}



.table-results-container tr:nth-child(even){
    /*background-color: #ecedf6;*/
    background-color: #f3f3f3;
    color: var(--clr-secundary);

}

.table-results-container tr:nth-child(odd) button{
    background-color: transparent;
}

.table-results-container tr:nth-child(even) button{
    color: var(--clr-secundary);
    background-color: transparent;
}

.table-results-container tr button:hover{
    color: var(--clr-primary);
}

.table-results-container tr:hover {
    /*background-color: #ddd;*/
    font-weight: bold;
    color: var(--clr-secundary);
}

.table-results-container th {
    padding-top: 12px;
    padding-bottom: 12px;
    /*text-align: center;*/
    background-color: var(--clr-secundary);
    color: white;
}
.table-results-container tbody tr{
    border-bottom: 1px solid #dddddd;
}

.table-results-container button{
    border: none;
    margin-left: 10px;
}
.table-results-container button:nth-child(even){
    color: #008058;
}

.th-competitor{
    width: 15%;
}
.th-status{
    width: 20%;
}
.th-start-time{
    width: 15%;
}
.th-finish-time{
    width: 15%;
}
.th-competition-time{
    width: 7.5%;
}
.th-competition-time-diff{
    width: 7.5%;
}
.th-checkpoints{
    width: 10%;
}
.th-position{
    width: 10%;
}

.td-name div:last-child{
    width: 140px;
    /*border: #0288d1 solid 1px;*/
    display: inline-block;
}
.td-name div:first-child{
    margin-right: 10px;
    width: 35px;
    /*border: #0288d1 solid 1px;*/
    display: inline-block;
}


@media screen and (max-width: 550px){
    .th-start-time{
        display: none;
    }
    .td-start-time{
        display: none;
    }
    .th-finish-time{
        display: none;
    }
    .td-finish-time{
        display: none;
    }

    .th-status{
        display: none;
    }
    .td-status{
        display: none;
    }
    .th-competition-time-diff{
        display: none;
    }
    .td-competition-time-diff{
        display: none;
    }

    .th-checkpoints{
        display: none;
    }

    .td-checkpoints{
        display: none;
    }

    .table-results-container{
        font-size: 0.8rem;
    }

    .th-competitor{
        width: 55%;
    }

    .th-competition-time{
        width: 27%;
    }

    .th-position{
        width: 18%;
    }
    .td-position{
        text-align: center;
        vertical-align: middle;
    }


    .table-results-container td, .table-results-container th {
        border: 1px solid #ddd;
        padding: 4px;
    }
    .table-results-container th {
        padding-top: 5px;
    }
    .table-results-container h4{
        margin-left: 10px;

        font-size: 14px;
    }

    .handled-export-button {
        display: none;
    }

}
