/*Phone*/
.race-cards-wrapper{
    display: grid;
    grid-template-columns: minmax(300px, 1fr);
    justify-content: center;
    grid-gap: 2em;
    margin: 5vh 5vw;


}
.race-cards-wrapper a:visited{
    color: var(--clr-secundary);
    background-color: transparent;
    text-decoration: none;

}
.race-cards-wrapper a:hover{
    color: var(--clr-primary);
    background-color: transparent;
    text-decoration: none;
    padding: 2px;
}

.race-card-container span{
    vertical-align: middle;
}

.race-card-container p{
    padding-left: 0.5em;
    padding-right: 0.5em;
}
.race-card-image{
    position: absolute;
    height: 13em;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.race-card-paragraph {
    font-size: 9px;
}

.race-card-container{

    box-shadow: 0px 2px 8px 0px var(--clr-gray-light);
    text-align: left;
    border-radius: 0.7em;
    position: relative;
    background-color: white;
    overflow: hidden;
    font-size: 12px;
    /*font-size: 1.5vh;*/
}

.race-card-body{
    padding-left: 0.4em;
}



.race-card-title{
    margin-top: 11.8em;
    font-size: 1.2em;
    text-align: center;
    font-weight: 600;

}

.race-card-subtitle{
    font-size: 1em;
    text-align: left;

}
.race-card-container h5{
    font-size: 1.1em;
    text-align: left;
}
/*Tablet*/
@media screen and (min-width: 590px) {
    .race-cards-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
    .race-card-image{
        height: 14em;
    }
    .race-card-container{
        font-size: 1vw;
    }
    .race-card-title{
        margin-top: 12em ;
    }
    .race-card-paragraph {
        font-size: 0.8vw;
    }
}

/*Desktop*/
@media screen and (min-width: 1000px) {
    .race-cards-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
    .race-card-image{
        position: absolute;
        height: 15em;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

    }
    .race-card-container{

        box-shadow: 0px 2px 8px 0px var(--clr-gray-light);
        text-align: left;
        border-radius: 0.7em;
        position: relative;
        background-color: white;
        overflow: hidden;
        font-size: 0.9vw;
        /*font-size: 1.1vw;*/
    }
    .race-card-title{
        margin-top: 13em;
        font-size: 1.2em;
        text-align: center;
        font-weight: 600;

    }
    .race-card-paragraph {
        font-size: 12px;
    }


}


