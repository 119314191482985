
.competitorIcon{
    background-color: transparent;
    opacity: .75 !important;

}

.mapContainer{
    height: 70vh;

}

.divIcon{
    background-color: transparent;

}