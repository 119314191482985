

/*.button-rapper{*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    background-color: blueviolet;*/
/*}*/

.back-button{

    background-color: var(--clr-secundary);
    color: white;
    padding: 0.6vw 1.2vw;
    text-align: center;
    font-size: 1em;
    border-radius: 4px;
    border: none;
    margin-bottom: 5px;
    font-weight: 600;
}

.back-button:hover{
    transition: 0.4s;
    background-color: var(--clr-primary);
    border: 2px solid var(--clr-primary);
}