
.map-title-rapper{
    /*font-family: Roboto;*/
    /*font-family: Dubai;*/
    /*background-color:  #008058;*/
    /*opacity: 0.25;*/
    margin-bottom: -20px;

}

.map-title-rapper p{
    font-size: 20px;

}

