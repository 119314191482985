
.competition-registration-container{
    margin-top: 35px;
    display: grid;
    grid-template-columns: repeat(10,1fr);
    gap: 20px;
}

.competition-registration-container h5{
    font-size: 18px;
    font-weight: 600;
}

.registration-title{
    border-bottom: #F5EEEE solid 2px;
    margin-bottom: 15px;
}
.registration-form-control input[type = 'text'], [type = 'email'], [type = 'date'], [type = 'time']{
    display: block;
}

.competition-registration-1block{

    grid-column: 4/7;
}

.registration-form-container label{
    font-size: 12px;
    font-weight: 600;
    line-height: 5px;
}

.registration-form-container input{
    border-style: solid;
    border-width: 2px;
    border-color: #F0E1E1;
    border-radius: 3px;
    margin-bottom: 5px;
}

.registration-form-control select{
    border-style: solid;
    border-width: 2px;
    border-color: #F0E1E1;
    border-radius: 3px;
    margin-bottom: 5px;
    font-size: 16px;
    width: 33%;

}

.registration-error{
    color: var(--clr-primary);
    font-size: 10px;
}
/*.registration-form-container input[type="radio"]{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    flex-direction: row;*/
/*}*/

.registration-form-container input[type="radio"]:checked{
    background-color: black;

}
.registration-form-container input[type="radio"]:hover{
    background-color: var(--clr-secundary);
    transition: color 400ms ease-in-out;
}
.registration-form-back-button{
    margin-left: 180px;
    background-color: transparent;
    color: var(--clr-secundary);
    border: none;
    font-size: 14px;
    transition: color 400ms ease-in-out;
}
.registration-form-back-button:hover{

    color: var(--clr-primary);
    padding: 2px;
}


.competition-registration-2block{
    grid-column:7/10 ;
}

.pay-select{
    margin-top: 20px;
    margin-bottom: 7px;
}

.submit-pay{
    margin-bottom: 10px;
}

.submit-pay input{
    background-color: var(--clr-secundary);
    color: white;
    width: 53%;
    padding: 3px 8px 3px 8px;
    border-color: var(--clr-secundary);
    border-radius: 3px;
}
.submit-pay input:hover{
    padding: 5px 14px 5px 14px;
    background-color: var(--clr-primary);
    border-color: var(--clr-primary);
 }

.registration-total{
    margin-top: 20px;
    border-top: #F5EEEE solid 2px;
    padding-top: 10px;
}


.summarize img{
    max-width: 200px;
    margin-bottom: 10px;
    border-radius: 3px;
    width: 80px;
    height: 60px;
}
.summarize{
    border: #F5EEEE 2px solid;
    padding: 20px;
    width: 66%;
}
.summarize h4{
    font-weight: 600;
}