.voucher-content-container{
    margin-left: 60px;
    margin-right: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 20px;
}

.voucher-content-container img{
    position: relative;
    width: 170px;
    height: 100px;
    /*top: -100px;*/
    left: -30px;

}

.voucher-content-container div{
    margin-bottom: 12px;

}

.voucher-content-container div:last-child{
    margin-bottom: 0px;
}

.voucher-title{
    /*display: flex;*/
    /*border: black solid 1px;*/
    /*justify-content: center;*/
    margin-right: auto;
    margin-left: auto;
    /*border: #0A253C solid 1px;*/

   padding: 15px;

}

.voucher-right-content {
    margin-left: auto;
    margin-right:50px;

}