
.map-search-box-div{
    font-family: Dubai, serif;
    font-size: 15px;
    /*border: solid black;*/
    display: flex;
    overflow: auto
}

.input-box-container{
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.map-search-box-input{
    display: none;
    border-width: 3px;
    border-color: #f35744;
    border-radius: 5px;
    padding: 6px;

}

.map-search-box-button{
    background-color: #ffff;
    border: 1px solid #c2bfba;
    color: black;
    padding: 10px 10px;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    margin-right: 1.3vw;
    max-height: 45px;
}

.map-search-box-button:hover{
    background-color: #f4f4f4;
}

.search-icon{
    font-size: 22px;
}

.map-search-ul{

    display: none;
    background-color: #f4f4f4;
    float: right;
    list-style-type: none;
    max-height: 25vh;
    overflow:hidden;
    overflow-y:scroll;
    border-radius: 5px;

}

.map-search-ul li{

    font-size: 15px;
    padding: 7px;
    border-bottom: solid #D1C4C4;
    border-width: thin;
}

.map-search-ul li:hover{
    padding: 9px;
}