.container{
    display: flex;
    flex-direction: column;
    height: 77vh;
}

.table-fixed {
    /*height: 100%;*/
    overflow-y: scroll;
    width: 100%;
    font-size: 13px;
}
/*.table-fixed tr{*/
/*    height: 5px;*/
/*}*/