
.Navbar{
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: left;
    align-items: center;
    /*position: relative;*/
    max-height: 60px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);

}

.logo-container img{
    width: 85px;
    height: 120px;
}
/*.links{*/
/*    display: flex;*/
/*    !*width: 100%;*!*/
/*    !*margin-left: 15px;*!*/
/*    justify-content: left;*/
/*    align-items: center;*/
/*    !*background-color: blueviolet ;*!*/

/*}*/


.Navbar #hidden{
    display: flex;
}

.Navbar a{
    color: var(--clr-secundary);
    margin-left: 18px;
    font-size: 18px;
}
.Navbar a:hover{
    color: var(--clr-primary);
    padding-top: 10px;
    text-decoration: none;
}

.Navbar button{
    display: none;
    margin-left: 20px;
    color: var(--clr-gray-dark);
    background-color: white;
    border-radius: 3px;
    border:solid 1px;
    padding: 3px 5px;

}



@media screen and (max-width: 700px){
 .Navbar a{
     font-size: 15px;
 }
}

@media screen and (max-width: 550px){
    .Navbar{
        height: 40px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .Navbar button{
        display: flex;
    }
    /*.logo-container{*/
    /*    display: none;*/
    /*}*/
    .logo-container img{
        width: 60px;
        height: 90px;
        margin-right: 15px;
    }
    .links{
        display: none;
    }
    .Navbar #hidden{

        position: absolute;
        left: 0px;
        top: 50px;
        height: 150px;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        /*z-index: 2;*/

    }
    .Navbar #hidden a{
        margin: 10px;
        font-size: 15px;


    }


}