.card-checkout-form-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}


.card-checkout-form-container{
    position: relative;
    background-color: white;
    width: 350px;
    /*margin: auto;*/
    /*margin-top: 50px;*/
    /*box-shadow: 0px 2px 8px 0px var(--clr-gray-light);*/
    /*border-radius: 8px;*/
    animation-name: card-checkout-modal-animation;
    animation-duration:1s;

}

@keyframes card-checkout-modal-animation {

    0%{top: -500px}
    100%{top: 0}
}


.card-checkout-form-container form button{
    background-color: var(--clr-secundary);
    color: white;
    border-radius: 3px;
    border-color: transparent;
    margin-top: 15px;
    font-size: 13px;
    padding: 4px;
    transition: opacity 1s;
}

.card-checkout-form-container form button:hover{
    opacity: 0.9;

}

.card-checkout-form-container form{
    padding: 20px;
}

.card-checkout-form-container form img:first-of-type{
    max-width: 200px;
    margin-bottom: 10px;
    border-radius: 3px;
    width: 200px;
    height: 100px;

}

.card-checkout-form-container  img:last-of-type{
    margin-top: 5px;
    width: 100px;
    height: 25px;
    margin-bottom: 0;
    border: none;
    display: block;
    float: right;
}

.card-payment-form-close-button {
    position: absolute;
    right: 10px;
    background-color: transparent;
    border: none;

}
.card-payment-form-close-button:hover{
    padding: 1px;
}
.card-payment-form-close-button button{
    background-color: transparent !important;
}
.card-payment-form-close-button button:hover{
    padding: 1px;
}
