
.checkout-modal-container{
    /*margin-top: 20px;*/
    width: 350px;
    /*height: 250px;*/
    background-color: white;
    padding: 15px;
    /*border: solid #008058;*/
    /*border-radius: 5px;*/

    position: relative;
    /*top: 40%;*/
    /*left: 50%;*/
    /*margin-top: -50px;*/
    /*margin-left: -50px;*/
}
.checkout-modal-container h5{
    font-size: 14px;
    border-top: solid 1px var(--clr-secundary);
    padding-top: 20px;
    font-weight: 600;
}
.checkout-modal-container button:first-of-type{
    /*margin-right: 50%;*/
    background-color: var(--clr-secundary);
    color: white;
    border-radius: 3px;
    border-color: transparent;
    margin-top: 15px;
    font-size: 13px;
    padding: 4px;
    transition: opacity 1s;
}

.checkout-modal-container button:last-of-type{
    /*margin-right: 50%;*/
    display: block;
    background-color: var(--clr-primary);
    color: white;
    border-radius: 3px;
    border-color: transparent;
    margin-top: 15px;
    font-size: 13px;
    padding: 4px;
    transition: opacity 1s;
}